import React, { useEffect, useState } from 'react'
import { questionBankService } from '../../../service/questionBankService'
import ModalInputSimulation from './ModalInputSimulation'
import { CalendarViewMonthOutlined, List } from '@mui/icons-material'
import DOMPurify from 'dompurify';
import jsonData from './r.json';
import { useSelector } from 'react-redux';

const SimulationTableExerciseView = () => {
  const [data, setData] = useState(null)
  const [celldata, setCelldata] = useState(null)
  const [loading, setLoading] = useState(false)
  const [header, setHeader] = useState([])
  const [selectedCell, setSelectedCell] = useState(null)
  const [isModalOpen, setisModalOpen] = useState(false);

  const simulationData = useSelector((state) => state.exercise.dummySimulation);


  const handleCellClick = (cell) => {
    console.log('selected cell')
    setSelectedCell(cell);
  };

  const handleCloseModal = () => {
    setSelectedCell(null);
    setisModalOpen(false);
  };

  const handleSaveModal = (data) => {
    console.log(data, 'data in save');
    let rowsArr = celldata.map(row => ({ ...row, [selectedCell.columnType]: { ...row[selectedCell.columnType] } }));
    let rowObj = rowsArr[selectedCell.rowIndex];
    // let columnObj = rowObj[selectedCell.columnType] ;
    if (data?.questionType === 'text') {
      rowObj[selectedCell.columnType].value = data?.options[data.correctOption].value;
    } else {
      rowObj[selectedCell.columnType].value = data?.cellValue;
    }
    console.log(rowObj[selectedCell.columnType], 'columnObj');
    console.log(rowsArr, 'columnObj rowsArr');

    // columnObj.isQuestion = data?.isQuestion;
    // if (data.isQuestion && selectedCell.questionType === 'text') {
    //   columnObj.options = data.options;
    //   columnObj.correctOption = data?.correctOption;
    // }
    setCelldata(rowsArr);
    setisModalOpen(false);
    setSelectedCell(null);
  };



  const fetchData = async () => {
    setData(simulationData)
    setCelldata(simulationData?.cellData)
    return
    setLoading(true)
    const id = 4
    try {
      const response = await questionBankService().getSimulationDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
        setCelldata(response?.data?.cellData)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    // if (celldata?.length > 0) setHeader(Object.keys(celldata[0]),)
    // const ddata = jsonData?.cellData
    if (celldata?.length > 0) {
      const firstObject = celldata[0]
      setHeader(Object.values(firstObject).map(item => item.columnName))
    }

    // if (ddata.length > 0)setHeader()
  }, [celldata])

  useEffect(() => {
    if (selectedCell) {
      console.log('modal trigger')
      setisModalOpen(true);
    }
  }, [selectedCell]);


  let answerArray = [{ a1: '2024-07-04' }, { b1: 'Add: Receipts column undercast' }, { d1: 200 },
  { c2: 1000 }]
  let answersObject = {
    a1: '2024-07-04',
    b1: 'Add: Receipts column undercast',
    d1: 200,
    c2: 1000

  }

  const onCheckCorrectAnswer =()=>{
    // answersObject
  }

  const shwCorrectAnswerNow = true

  const isCorrectAnswer=(cellItem) =>{
    let cellValue = cellItem?.questionType === 'number' ? parseInt(cellItem.value) : cellItem.value
    
        return cellValue === answersObject[cellItem?.cell]
  }

  const getCellStyle =(cellItem)=>{

    // return {borderWidth:'2px',borderColor:'#cd1a3d '}
    // border ${cellItem?.isQuestion ? '!border-amber-300 border-2 ' : 'border-gray-300 '}
    if(shwCorrectAnswerNow &&  isCorrectAnswer(cellItem) ){
      return {borderWidth:'2px',borderColor:'green '}
    }else if(shwCorrectAnswerNow && cellItem?.isQuestion && !isCorrectAnswer(cellItem)){
      console.log(cellItem,'cellItem in wrong')
      return {borderWidth:'2px',borderColor:'#cd1a3d '}
    }else if(cellItem?.isQuestion){
      return {borderWidth:'2px',borderColor:'orange '}
    }else{
      return {borderWidth:'1px',borderColor:'gray '}

    }
  }

  return (
    <div className='h-[85%]'>
      {loading ? <div>Loading....</div> : <div className="p-6  bg-white">
        {/* <h2 className="text-xl font-semibold mb-8">{data?.question}</h2> */}
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.question) }} />
        <div style={{borderColor:'wheat'}} className="grid grid-cols-2 gap-4 mt-8">
          <table>
            <thead>
              <tr>
                <th></th>
                {header.map(i => <th key={i} className='min-w-52  p-2 border border-gray-300'>{i.toUpperCase()}</th>)}

                <th></th>
              </tr>
            </thead>
            <tbody>
              {celldata?.map((row, rowIndex) => {
                const rowAsArray = Object.entries(row)
                // console.log(rowAsArray[1], 'row as array')
                return <tr key={rowIndex}>
                  <td className='border border-gray-300'>{rowIndex + 1}</td>
                  {rowAsArray.map((col, colIndex) => {
                    // console.log(col[1], 'col', rowIndex)
                    const cellItem = col[1]
                    return (
                      <td onClick={() => {
                        if (cellItem?.isQuestion) {
                          handleCellClick(cellItem)
                        }
                      }} className={` relative`} style={getCellStyle(cellItem)} >{cellItem?.value}
                        {cellItem?.isQuestion && <div className="w-4 h-4 rounded bg-gray-400 absolute top-2 right-2 grid place-content-center">
                          {cellItem.isQuestion && cellItem.questionType === 'date' ? <CalendarViewMonthOutlined style={{ color: '#fff', fontSize: '12px' }} /> : null}
                          {cellItem.isQuestion && cellItem.questionType === 'text' ? <List style={{ color: '#fff', fontSize: '12px' }} /> : null}
                          {cellItem.isQuestion && cellItem.questionType === 'number' ? <p className='text-white font-bold text-[8px]'>123</p> : null}
                        </div>}
                      </td>
                    )
                  })}


                </tr>
              })}

            </tbody>
          </table>
        </div>
      </div>}
      {selectedCell ? (
        <ModalInputSimulation
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveModal}
          cellData={selectedCell}
        />
      ) : null}
    </div>
  )
}

export default SimulationTableExerciseView