import React, { useState, useEffect } from 'react';
import Select, { createFilter } from 'react-select';
import axios from 'axios';
import { Controller } from 'react-hook-form';
import TooltipError from '../tooltipError/TooltipError';
import { publicService } from '../../service/publicService';

const AutoComplete = ({ control, errors }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputValue) {
                let queryOptions
                setIsLoading(true);
                publicService().getSchoolAutoComplete(inputValue)
                    .then(response => {

                        if (response.status === 200 && response.data.length > 0) {
                            queryOptions = response.data.map(school => ({
                                value: school,
                                label: school,
                            }))
                        } else {
                            queryOptions = [{ value: inputValue, label: inputValue }]
                        }
                        setOptions(queryOptions);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setOptions([{ value: inputValue, label: inputValue }]);
                        setIsLoading(false);
                        console.error('Error fetching school suggestions:', error);
                    });
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue]);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);
    };

    const handleChange = (option) => {
        setSelectedOption(option);
    };
    
    useEffect(() => {
        console.log(options, 'options')
    }, [options])

    return (
        <div>
            <label className="block text-gray-700 text-[12px]">School</label>
            <Controller
                name="school"
                control={control}
                render={({ field }) => (
                    <Select
                        {...field}
                        value={selectedOption}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        onChange={(option) => {
                            field.onChange(option.value);
                            handleChange(option);
                        }}
                        options={options}
                        isLoading={isLoading}
                        className={`mt-1 p-2 w-full border text-[12px] rounded ${errors.school ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Type to search for a school..."
                        filterOption={createFilter({ ignoreAccents: false })}
                    />
                )}
            />
            {errors.school && <TooltipError message={errors.school.message} />}
        </div>
    );
};

export default AutoComplete;
