import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { dashboardService } from '../../../service/dashboardService';
import { questionBankService } from '../../../service/questionBankService';
import { mcqSchema } from '../../../utils/schema';
const optionLabels = ['A','B','C','D']

const CreateMCQ = () => {
  const { control, handleSubmit, formState: { errors },reset } = useForm({
    resolver: yupResolver(mcqSchema),
    defaultValues: {
      question: '',
      options: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
      correctOption: null,
    },
  });

  const onSubmit = async (data) => {
    console.log(data);

    const formData = {
      grade: 1,
      lecture: 2,
      questions: [
        {
          question:data?.question,
          option_a: data?.options[0].value,
          option_b: data?.options[1].value,
          option_c: data?.options[2].value,
          option_d: data?.options[3].value,
          correct_option: optionLabels[data?.correctOption]
        }
      ]
    }
    try {
      const response = await questionBankService().createMCQ(formData)
      if (response?.status === 201) {
        reset()
        toast.success('MCQ created !!')

      } else {
        const responseObj = response?.response?.data
        const firstKey = Object.keys(responseObj)[0];
        const message = responseObj[firstKey][0]
        console.log(message, 'msg')
        toast(message)
      }

    } catch (error) {
      console.log(error.message, 'error')
      toast('Something went wrong try again later')
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-5 border rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-5"></h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Question</label>
          <Controller
            name="question"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={`w-full p-2 border ${errors.question ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
            )}
          />
          {errors.question && <p className="text-red-500 text-sm mt-1">{errors.question.message}</p>}
        </div>
        <div className="mb-4 flex flex-wrap gap-4 w-full">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="mb-2 w-[45%]">
              <label className="block text-sm font-medium text-gray-700 mb-1">Option {index + 1}</label>
              <div className="flex items-center">
                <Controller
                  name={`options.${index}.value`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      className={`w-full p-2 border ${errors.options?.[index]?.value ? 'border-red-500' : 'border-gray-300'} rounded`}
                    />
                  )}
                />
                <Controller
                  name="correctOption"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="radio"
                      value={index}
                      checked={field.value == index}
                      onChange={() => field.onChange(index)}
                      className="ml-2"
                    />
                  )}
                />
              </div>
              {errors.options?.[index]?.value && <p className="text-red-500 text-sm mt-1">{errors.options[index].value.message}</p>}
            </div>
          ))}
          {errors.correctOption && <p className="text-red-500 text-sm mt-1">{errors.correctOption.message}</p>}
        </div>
        <div className="text-center">
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CreateMCQ;
