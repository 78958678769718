import React, { useEffect, useState } from 'react'
import Chapter from '../studentDashbaord/chapter/Chapter'
import { dashboardService } from '../../service/dashboardService'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import ProgressBar from '../../components/progressBar/ProgressBar'

const StudyPlan = () => {

    const [modules, setModules] = useState([])

    const fetchModules = async () => {
        try {
            const response = await dashboardService().getLectures()
            if (response?.status === 200) {
                setModules(response?.data?.results)
            } else {
                toast.error('Something went wrong')
            }
            console.log(response, 'response')
        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        fetchModules()
    }, [])

    return (
        <div>
            <h2>Study Plan</h2>
            {modules.map(i => <Chapter chapter={`${i.title}`} isLocked={false} key={i}>
            <a href="#video1" className="block hover:underline bg-gray-200 mb-4 p-4 rounded  hover:bg-gray-400 duration-300 ease-in"> {`Lecture Video `} </a>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'mcq',
                    gradeId:i?.grade?.id
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">MCQs ({i?.total_mcq})</p>
                    <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div>
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'simulation',
                    gradeId:i?.grade?.id
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Simulation Tests ({i?.total_simulation})</p>
                    <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div>
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'long_answer',
                    gradeId:i?.grade?.id
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Long Answers ({i?.total_long_answer})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>

            </Chapter>)}
         
            {modules.map(i => <Chapter chapter={`${i.title}`} isLocked={true} key={i}>
                <a href="#video1" className="block hover:underline bg-gray-200 mb-4 p-4 rounded"> {`Lecture Video `} </a>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'mcq'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">MCQs ({i?.total_mcq})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'simulation'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Simulation Tests ({i?.total_simulation})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'long_answer'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Long Answers ({i?.total_long_answer})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>

            </Chapter>)}
         
            {modules.map(i => <Chapter chapter={`${i.title}`} isLocked={true} key={i}>
                <a href="#video1" className="block hover:underline bg-gray-200 mb-4 p-4 rounded"> {`Lecture Video `} </a>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'mcq'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">MCQs ({i?.total_mcq})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'simulation'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Simulation Tests ({i?.total_simulation})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>
                <Link to="/attend-test" state={{
                    lectureId: i.id,
                    testType: 'long_answer'
                }} className='flex items-center justify-between mb-4 bg-gray-200 p-4 rounded hover:bg-gray-400 duration-300 ease-in'> <p className="block">Long Answers ({i?.total_long_answer})</p>
                    {/* <div className="w-1/2">
                        <ProgressBar completed={10} total={24} />
                    </div> */}
                </Link>

            </Chapter>)}
         


        </div>
    )
}

export default StudyPlan