import React, { useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';

const StaffsListing = ({list,isLoading,onDeleteConfirm}) => {
   
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Name</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Mail</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Action</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item,index) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.first_name} {item.last_name}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.email}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700 cursor-pointer" onClick={()=>onDeleteConfirm(item.id)}>
                <Delete />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StaffsListing;
