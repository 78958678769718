import { useState } from "react";


const Chapter = ({ chapter, children,isLocked }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4 relative">
            <div
                className="p-2 cursor-pointer hover:bg-gray-300 text-[tomato]"
                onClick={() => setIsOpen(!isOpen)}
            >
                {chapter}
            </div>
            {isOpen && (
                <div className="pl-4 pt-2 text-sm">
                    {children}
                </div>
            )}
            {isLocked ? 
            <div className="absolute top-0 right-4">
                <svg
                    className="w-8 h-8 text-black"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 17v-1m0-4a3 3 0 00-3 3v1a3 3 0 003 3v-4zm0-8a4 4 0 00-4 4v4h8v-4a4 4 0 00-4-4zM5 11v8a2 2 0 002 2h10a2 2 0 002-2v-8a2 2 0 00-2-2H7a2 2 0 00-2 2z"
                    ></path>
                </svg>
            </div>
            :null}
        </div>
    );
};

export default Chapter