import React, { useEffect, useState } from 'react'
import AddStaffForm from './AddStaffForm'
import StaffsListing from './StaffsListing'
import { toast } from 'react-toastify'
import { dashboardService } from '../../service/dashboardService'
import PrimaryButton from '../../components/button/PrimaryButton'

const Staffs = () => {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const fetchList = async () => {
    setIsLoading(true)
    try {
      const response = await dashboardService().getStaffsList()
      setIsLoading(false)
      if (response.status === 200) {
        setList(response.data.results)
      }
      console.log(response.data, 'response')
    } catch (error) {
      toast.error('Failed to retireve list')
    }
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const addStaffcallback = () => {
    setShowModal(false)
    fetchList()
  }


  const onDeleteConfirm = async (id) => {
    try {
      const response = await dashboardService().deleteStaff(id)
      toast.success('Staff Deleted')
      fetchList()
      console.log(response, 'res')
    } catch (error) {
      console.log(error.message, 'error')
    }

  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <div className="w-full mx-auto mt-10 relative">
      <AddStaffForm successCallback={addStaffcallback} isOpen={showModal} handleClose={handleModalClose} />
      <div className='flex w-full justify-end'>
        <PrimaryButton onClick={() => setShowModal(true)}>Add</PrimaryButton>
      </div>
      <StaffsListing list={list} isLoading={isLoading} onDeleteConfirm={onDeleteConfirm} />
    </div>
  )
}

export default Staffs