import React, { useEffect, useState } from 'react'
import { publicService } from '../../service/publicService';

const QuestionBankFilters = ({ selectedGrade, setSelectedGrade}) => {
    const [grades, setGrades] = useState([]);
    const fetchGradesList = async () => {
        try {
            const response = await publicService().getGradesList()
            if (response?.status === 200) {
                setGrades(response?.data?.results)
            }
            console.log('grades', response)
        } catch (error) {
            console.log('error', error.message)
        }
    }

    useEffect(() => {
        fetchGradesList()
    }, [])

   

    return (
        <div className="container mx-auto p-4 flex">
            <div className="mb-4">
                <label htmlFor="grade" className="block text-sm font-medium text-gray-700">Select Grade</label>
                <select
                    id="grade"
                    className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 "
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                >
                    <option value="">Select a grade</option>
                    {grades.map((grade) => (
                        <option key={grade.id} value={grade.id}>
                            {grade.grade}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    )
}

export default QuestionBankFilters