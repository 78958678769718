import { Modal } from '@mui/material';
import React, { useState } from 'react'
import { Controller } from 'react-hook-form';

const SimulationOptionsModal = ({ options, selectedOption, control }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const question = "What is the capital of France?";
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <div>
            <div className="grid grid-cols-2 gap-4">
                {options.map((option, index) => (
                    <Controller
                        key={index}
                        name="correctOption"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <div
                                onClick={() => onChange(index)}
                                className={`p-4 rounded-lg shadow-md cursor-pointer`}
                                style={{ background: value === index ? 'orange' : '#fff' }}
                            >
                                <label className="flex items-center space-x-3">
                                    <span className="text-gray-700">{option.value}</span>
                                </label>
                            </div>
                        )}
                    />
                ))}
            </div>
        </div>
    )
}

export default SimulationOptionsModal