import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { ChevronLeft, ChevronRight, Flag, FlagOutlined, Refresh } from '@mui/icons-material'
import McqExerciseView from './McqExerciseView'
import SimulationTableExerciseView from './SimulationTableExerciseView'
import { useLocation, useNavigate } from 'react-router-dom'
import ResultModal from './ResultModal'
import { useDispatch } from 'react-redux'
import { showAnswer } from '../../../redux/exerciseTestSlice'
import { questionBankService } from '../../../service/questionBankService'
import { testService } from '../../../service/testService'

function TestExerciseLayout() {
    const location = useLocation();
    const { testType,lectureId,gradeId } = location.state;
    const [questions,setQuestions] = useState([])
    const [currentQuestionIndex,setCurrentQuestionIndex]= useState(0)

    const [selectedOption, setSelectedOption] = useState(null)
    const [correctAnswer, setCorrectAnswer] = useState(null)


    const navigate = useNavigate()

    const [showResultModal, setShowResultModal] = useState(false)
    const dispatch = useDispatch()

    console.log(testType, 'state data')

    const handleResultModalClose = () => {
        setShowResultModal(false)
        navigate('/study')
    }

    const handleFlag = () => {

    }


    const startTest = async()=>{
        const response = await testService().startExerciseTest('mcq',lectureId)
        if(response?.status === 200){
            fetchQuestions()
        }else{
            console.log('error start')
        }
    }

    const fetchQuestions = async()=>{
        const response = await questionBankService().listMCQ(gradeId,lectureId)
        console.log(response,'response')
        if(response?.status === 200){
            setQuestions(response?.data?.results)
        }else{
            console.log('error fetch')
        }
    }

    useEffect(() => {
        startTest()
    
    }, [lectureId])
    


    return (
        <div className=' relative'>
            <div className="flex w-full justify-between items-center  border-b-2 p-2">
                <p className="text-xl font-bold">Module 2 - Understanding core concepts - MCQ</p>
                <PrimaryButton onClick={() => setShowResultModal(true)} type="button" className='text-white bg-highlight' >
                    Exit
                </PrimaryButton>
            </div>
            <div className='flex items-center justify-start p-4 gap-52'>
                <p className={`text-xl  text-amber-500 font-bold `}>{currentQuestionIndex + 1}/{questions?.length} </p>
                {testType === 'simulation' &&  <button onClick={() => dispatch(showAnswer(true))} className='p-2 bg-amber-500 rounded text-center text-sm text-white'>Show answer</button>}
                {testType === 'simulation' && <Refresh />}

            </div>

            <div className="flex bg-gray-200">
                {/* //exam area */}
                <div className="w-2/3 bg-white border rounded p-2 h-[65vh] overflow-auto relative" >
                    <div onClick={handleFlag} className='absolute cursor-pointer  border border-gray-400 rounded-full  grid place-content-center w-12 h-12 top-0 right-4 hover:bg-gray-200'>
                        {/* <FlagOutlined />  */}
                        <Flag style={{ color: 'green' }} />
                    </div>
                    {/* <McqExerciseView /> */}
                    {testType === 'simulation' && <SimulationTableExerciseView />}
                    {testType === 'mcq' && <McqExerciseView setCorrectAnswer={setCorrectAnswer} correctAnswer={correctAnswer} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]?.id} />}

                </div>
                <div className="h-full">

                </div>
            </div>
            <ResultModal lectureId={lectureId} isOpen={showResultModal} handleClose={handleResultModalClose} />
            <div className='flex items-center justify-center gap-2'>
                <div onClick={()=>{
                    if(currentQuestionIndex != 0) {
                        setCorrectAnswer(null)
                        setSelectedOption(null)
                        setCurrentQuestionIndex( prev => prev - 1)
                    }
                }} className='cursor-pointer border p-4 rounded bg-gray-100'>
                    <ChevronLeft />
                </div>
                <div 
                onClick={()=>{
                    if(currentQuestionIndex != questions.length){
                        setCorrectAnswer(null)
                        setSelectedOption(null)
                        setCurrentQuestionIndex(prev => prev + 1)}
                }}
                  className='cursor-pointer  border p-4 rounded'>
                    <ChevronRight />
                </div>
            </div>
        </div>
    )
}

export default TestExerciseLayout