import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { signupSchema } from '../../../utils/schema'
import TooltipError from '../../../components/tooltipError/TooltipError';
import AutoComplete from '../../../components/autoCompleteInput/AutoComplete';
import { authService } from '../../../service/authService';
import PrimaryButton from '../../../components/button/PrimaryButton'
import { toast } from 'react-toastify';
import GradeSelectInput from './GradeSelectInput';

const SignupForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm({
        resolver: yupResolver(signupSchema),
    });
    const onSubmit = async (data) => {
        console.log(data);
        let formData = {
            email: data?.email,
            password: data?.password,
            first_name: data?.firstName,
            last_name: data?.surname,
            whatsapp_num: data?.whatsapp,
            school: data?.school,
            grade: data?.grade,
        }

        if(data.ref_code && data.ref_code.length > 0){
            formData.ref_code = data?.referal
        }
        try {
            const response = await authService().signUp(formData)
            if (response?.status === 201) {
                reset()
                toast('Signed Up . You are on waiting list!!')

            } else {
                const responseObj = response?.response?.data
                const firstKey = Object.keys(responseObj)[0];
                const message = responseObj[firstKey][0]
                console.log(message, 'msg')
                toast(message)
            }

        } catch (error) {
            console.log(error.message, 'error')
            toast('Something went wrong try again later')
        }
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col md:flex-row md:space-x-4 font-playfair">
            <div className="flex-1">
                <label className="block text-gray-700 text-xs">First Name</label>
                <input
                    type="text"
                    {...register('firstName')}
                    className={`mt-1 p-2 w-full border rounded ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.firstName && <TooltipError message={errors.firstName.message} />}
            </div>
            <div className="flex-1">
                <label className="block text-gray-700 text-xs">Surname</label>
                <input
                    type="text"
                    {...register('surname')}
                    className={`mt-1 p-2 w-full border rounded ${errors.surname ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.surname && <TooltipError message={errors.surname.message} />}
            </div>
        </div>
        <div>
            <label className="block text-gray-700 text-xs">Email Address</label>
            <input
                type="email"
                {...register('email')}
                className={`mt-1 p-2 w-full border rounded ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.email && <TooltipError message={errors.email.message} />}
        </div>
        <div>
            <label className="block text-gray-700 text-xs">Password</label>
            <input
                type="password"
                {...register('password')}
                className={`mt-1 p-2 w-full border rounded ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.password && <TooltipError message={errors.password.message} />}
        </div>
        <div>
            <label className="block text-gray-700 text-xs">WhatsApp No.</label>
            <input
                type="text"
                {...register('whatsapp')}
                className={`mt-1 p-2 w-full border rounded ${errors.whatsapp ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.whatsapp && <TooltipError message={errors.whatsapp.message} />}
        </div>
        <AutoComplete control={control} errors={errors} />
        <GradeSelectInput control={control} errors={errors} />
        <div>
            <label className="block text-gray-700 text-xs">Referral Code</label>
            <input
                type="text"
                {...register('referal')}
                className={`mt-1 p-2 w-full border rounded ${errors.referal ? 'border-red-500' : 'border-gray-300'}`}
            />
        </div>
        <PrimaryButton type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg">
            Sign Up
        </PrimaryButton>
    </form>
    
    
    )
}

export default SignupForm