import React, { useState } from 'react'
import QuestionBankFilters from './QuestionBankFilters';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/button/PrimaryButton';

const QuestionBank = () => {

    const [selectedGrade, setSelectedGrade] = useState('');

    const navigate = useNavigate()

    return (
        <div>
            <div className="flex justify-between">
                <h2 className='mb-8 font-bold text-xl'>Question Bank</h2>
                <PrimaryButton onClick={() => navigate('/create-question')}>Create Question</PrimaryButton>
            </div>
            <QuestionBankFilters setSelectedGrade={setSelectedGrade} selectedGrade={selectedGrade} />
            <div className='flex justify-start gap-4'>
                <div className="w-[25%] border-[2px] border-gray-400 hover:bg-gray-100  rounded-xl p-8 cursor-pointer relative overflow-hidden" onClick={() => navigate('/question-listing')}>
                    <div className='w-24 h-8 absolute top-0 right-0 bg-gray-600 text-white text-center font-bold'  >Grade 11</div>
                    <h3 className='text-xl font-bold mb-4'>Lecture - 1</h3>
                    <div className='flex flex-wrap gap-4'>
                        <p className='text-sm'>MCQ (20)</p>
                        <p className='text-sm'>Long answer (10)</p>
                        <p className='text-sm'>Simulation Table (4)</p>
                    </div>
                </div>

                <div className="w-[25%] border-[2px] border-gray-400 hover:bg-gray-100  rounded-xl p-4  cursor-pointer relative overflow-hidden">
                    <div className='w-24 h-8 absolute top-0 right-0 bg-gray-600 text-white text-center font-bold' >Grade 11</div>

                    <h3 className='text-xl font-bold mb-4'>Lecture - 2 </h3>
                    <div className='flex flex-wrap gap-4'>
                        <p className='text-sm'>MCQ (24)</p>
                        <p className='text-sm'>Long answer (6)</p>
                        <p className='text-sm'>Simulation Table (1)</p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default QuestionBank