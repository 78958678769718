import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dashboardService } from '../../service/dashboardService';
import { toast } from 'react-toastify';
import { formatDateToYMD } from '../../utils/helpers';
import GradeSelectInput from '../landing/signupForm/GradeSelectInput';
import BackNavSection from '../../components/backNav/BackNavSection';

const schema = yup.object().shape({
    title: yup.string().required('Title is required').max(255, 'Title must be at most 255 characters').min(1, 'Title must be at least 1 character'),
    description: yup.string().nullable(),
    video_url: yup.string().url('Must be a valid URL').required('Video URL is required').max(200, 'Video URL must be at most 200 characters').min(1, 'Video URL must be at least 1 character'),
    unlocking_date: yup.date().nullable(),
    always_allowed: yup.boolean(),
    grade: yup.number().nullable(),
});

const CreateModule = () => {

    const { register, handleSubmit, formState: { errors }, control,reset } = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate()


    const navigateBack = () => navigate('/modules')

    const onSubmit = async data => {
        if (data.unlocking_date) {
            data.unlocking_date = formatDateToYMD(data.unlocking_date);
          }
          console.log(data);
        try {
            const response = await dashboardService().addLecture(data)
            if (response?.status === 201) {
                reset()
                toast('Created Module')
                navigateBack()
            } else {
                const responseObj = response?.response?.data
                const firstKey = Object.keys(responseObj)[0];
                const message = responseObj[firstKey][0]
                console.log(message, 'msg')
                toast.error(message)
            }

        } catch (error) {
            console.log(error.message, 'error')
            toast('Something went wrong try again later')
        }
    };


    return (
        <div className=" w-full p-12">
           <BackNavSection navigateBack={navigateBack} />
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 w-1/2 ">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Title*</label>
                    <input
                        type="text"
                        {...register('title')}
                        className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.title ? 'border-red-500' : ''}`}
                    />
                    {errors.title && <p className="mt-2 text-sm text-red-600">{errors.title.message}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        {...register('description')}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.description && <p className="mt-2 text-sm text-red-600">{errors.description.message}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Video URL*</label>
                    <input
                        type="text"
                        {...register('video_url')}
                        className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.video_url ? 'border-red-500' : ''}`}
                    />
                    {errors.video_url && <p className="mt-2 text-sm text-red-600">{errors.video_url.message}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Unlocking Date</label>
                    <input
                        type="date"
                        {...register('unlocking_date')}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.unlocking_date && <p className="mt-2 text-sm text-red-600">{errors.unlocking_date.message}</p>}
                </div>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        {...register('always_allowed')}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 block text-sm text-gray-900">Always allowed</label>
                </div>
                <GradeSelectInput  control={control} errors={errors}/>
                {/* <div>
                    <label className="block text-sm font-medium text-gray-700">Grade</label>
                    <input
                        type="number"
                        {...register('grade')}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.grade && <p className="mt-2 text-sm text-red-600">{errors.grade.message}</p>}
                </div> */}
                <div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateModule;
