import { Delete, RemoveRedEyeRounded } from '@mui/icons-material'
import React from 'react'

const QuestionsTable = ({list,onPreviewClick,onDeleteClick}) => {
  return (
    <table className="min-w-full bg-white border border-gray-200">
    <thead>
      <tr>
        <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
        <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Question</th>
        <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Created at</th>
        <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"></th>
        <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"></th>
      </tr>
    </thead>
    <tbody>
      {list.map((item,index) => (
        <tr key={item.id} className="hover:bg-gray-100">
          <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
          <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.question}</td>
          <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.created_at}</td>
          <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700 cursor-pointer" onClick={()=>onPreviewClick(item.id)}>
            <RemoveRedEyeRounded />
          </td>
          <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700" onClick={()=>onDeleteClick(item.id)}>
            <Delete />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  )
}

export default QuestionsTable