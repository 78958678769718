import { apiAuthenticatedRequest, apiRequest } from "../api/apiRequest";

export const authService = () => {
  return {
    signUp: (data, headers = {}) => {
      return apiRequest({
        method: 'POST',
        url: 'students/signup/',
        data,
        headers,
      });
    },
    login: (data, headers) => {
      return apiRequest({
        method: 'POST',
        url: '/users/login/',
        data,
        headers,
      });
    },
    resetPassword: (mail, headers = {}) => {
      return apiRequest({
        method: 'GET',
        url: `/accounts/${mail}/reset-password`,
        headers,
      });
    },
    verifyResetPassword: (token, headers) => {
      return apiRequest({
        method: 'GET',
        url: `/accounts/verify-password/${token}`,
        headers,
      });
    },
    changePassword: (data, acc_id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'PUT',
        url: `accounts/${acc_id}/change-password`,
        data,
        headers,
      });
    },
    changePasswordWithoutAuth: (data, acc_id, headers = {}) => {
      return apiRequest({
        method: 'PATCH',
        url: `accounts/${acc_id}/change-password`,
        data,
        headers,
      });
    },
  };
};
