import React from 'react'
import SignupForm from '../../landing/signupForm/SignupForm'

const Register = () => {
  return (
    <div className="flex flex-col md:flex-row min-h-[80vh]">
        <div className="w-full md:w-1/2 p-12 flex flex-col justify-center bg-white">
          {/* <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Sign Up (It's Free)</h2> */}
          <div className="bg-white px-8 pb-6 w-[80%] mx-auto ">
            <SignupForm />
          </div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center bg-gray-200">
          <div className="w-full h-full bg-black flex items-center justify-center text-white  shadow-lg">
            <div className="text-center p-4">
              <h3 className="text-3xl font-semibold mb-4">Video Frame</h3>
              <p className="text-lg">Your promotional video goes here.</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Register