import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dashboardService } from '../../service/dashboardService'
import { toast } from 'react-toastify'
import PrimaryButton from '../../components/button/PrimaryButton'
import Chapter from '../studentDashbaord/chapter/Chapter'
import { Edit } from '@mui/icons-material'

const ModulesListing = () => {
    const navigate= useNavigate()

    const [modules,setModules] = useState([])

    const fetchModules = async () => {
        try {
            const response = await dashboardService().getLectures()
            if(response?.status === 200){
                setModules(response?.data?.results)
            }else{
                toast.error('Something went wrong')
            }
            console.log(response, 'response')
        } catch (error) {
            console.log(error,'error')
        }
    }

    useEffect(() => {
        fetchModules()
    }, [])

    
  return (
    <div className='w-full'>
        <div className="w-full flex justify-end" >
            <PrimaryButton onClick={()=>navigate('/create-module')}>Create Module</PrimaryButton>
        </div>
        <div className="w-full">
            <h2>Modules</h2>
          
            <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Title</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">grade</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Unlock on</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"></th>
          </tr>
        </thead>
        <tbody>
          {modules.map((item,index) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700 hover:underline cursor-pointer" onClick={()=>navigate(`/module-detail/${item.id}`)}>{item.title}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item?.grade?.grade}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.unlocking_date}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                <button><Edit /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>

    </div>
  )
}

export default ModulesListing