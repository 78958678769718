

import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const testService = () => {
  return {
    checkExerciseMcqAnswer: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `questions/mcq/${id}/check-answer/`,
        data,
        headers
      })
    },
    startExerciseTest: (questionType='mcq',id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `exams/exercise/${questionType}/lecture/${id}/start/`,
        headers
      })
    },
    submitAnswer: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/exercise/mcq/${id}/submit/`,
        data,
        headers
      })
    },
    getSummary: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/exercise/mcq/lecture/${id}/summary/`,
        headers
      })
    },
    
  }
}
