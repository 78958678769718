import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal } from '@mui/material';
import PrimaryButton from '../../components/button/PrimaryButton';
import { toast } from 'react-toastify';
import { dashboardService } from '../../service/dashboardService';

const schema = yup.object().shape({
  first_name: yup.string().min(1, 'First name is required').required('First name is required'),
  last_name: yup.string().min(1, 'Last name is required').required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().min(1, 'Password is required').required('Password is required'),
});

const AddStaffForm = ({ isOpen, handleClose, successCallback }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async data => {
    setIsLoading(true)
    console.log(data);
    try {
      const response = await dashboardService().addStaff(data)
      setIsLoading(false)
      console.log(response, 'response')
      if (response?.status === 201) {
        reset()
        successCallback()
      }

    } catch (error) {
      console.log(error.message, 'error')
      toast('Something went wrong try again later')
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className="max-w-md mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-6">Add Staff</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              {...register('first_name')}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.first_name ? 'border-red-500' : ''
                }`}
            />
            {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name.message}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              {...register('last_name')}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.last_name ? 'border-red-500' : ''
                }`}
            />
            {errors.last_name && <p className="text-red-500 text-xs mt-1">{errors.last_name.message}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              {...register('email')}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.email ? 'border-red-500' : ''
                }`}
            />
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              {...register('password')}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.password ? 'border-red-500' : ''
                }`}
            />
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
          </div>

          <div>
            <PrimaryButton isLoading={isLoading} type="submit">Add Staff</PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddStaffForm;
