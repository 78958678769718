import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const questionBankService = () => {
  return {
    createMCQ: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/questions/mcq/`,
        data,
        headers
      })
    },
    listMCQ: ( grade,lecture,headers = {}) => {
        return apiAuthenticatedRequest({
          method: 'GET',
          url: `/questions/mcq${lecture ? `?grade=${grade}&lecture=${lecture}`:''}`,
          headers
        })
      },
      getMcqDetails: ( id,headers = {}) => {
        return apiAuthenticatedRequest({
          method: 'GET',
          url: `/questions/mcq/${id}`,
          headers
        })
      },

      createSimulation: (data, headers = {}) => {
        return apiAuthenticatedRequest({
          method: 'POST',
          url: `/questions/simulation/`,
          data,
          headers
        })
      },
      listSimulations: ( grade,lecture,headers = {}) => {
        return apiAuthenticatedRequest({
          method: 'GET',
          url: `/questions/simulation${lecture ? `?grade=${grade}&lecture=${lecture}`:''}`,
          headers
        })
      },
      getSimulationDetails: ( id,headers = {}) => {
        return apiAuthenticatedRequest({
          method: 'GET',
          url: `/questions/simulation/${id}`,
          headers
        })
      },
    
      
 
   
 
  }
}
