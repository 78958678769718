import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const dashboardService = () => {
  return {
    getStudentsList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students`,
        headers
      })
    },
    getStudentsPendingList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students/pending-approval/`,
        headers
      })
    },
    approveStudent: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'PATCH',
        url: `/students/${id}/approve/`,
        headers
      })
    },
    getProfile: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `students/me`,
        headers
      })
    },
    getStaffsList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/staffs/`,
        headers
      })
    },
    addStaff: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: '/staffs/',
        data,
        headers
      })
    },
    deleteStaff: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'DELETE',
        url: `/staffs/${id}`,
        headers
      })
    },
    getLectures: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/lectures/`,
        headers
      })
    },
    addLecture: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/lectures/`,
        data,
        headers
      })
    },
    getLectureDetails: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/lectures/${id}`,
        headers
      })
    }
  }
}
