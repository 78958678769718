import { ChevronLeft } from '@mui/icons-material'
import React from 'react'

const BackNavSection = ({navigateBack}) => {
    return (
        <div className="w-full mb-8">
            <button onClick={navigateBack}>
                <ChevronLeft />
            </button>
        </div>
    )
}

export default BackNavSection