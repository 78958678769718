import React, { useState } from 'react'
import QuestionBankFilters from './QuestionBankFilters'
import { useNavigate } from 'react-router-dom';
import BackNavSection from '../../components/backNav/BackNavSection';
import CreateMCQ from '../dashboard/testManagement/CreateMCQ';
import CreateQuestionFilters from './CreateQuestionFilters';
import CreateSimulation from '../dashboard/testManagement/CreateSimulation';

const CreateQuestion = () => {
    const [selectedGrade, setSelectedGrade] = useState('');

    const navigate = useNavigate()

    const navigateBack = () => {
        navigate('/question-bank')
    }

    const options = [
        { value: '', label: 'Select an question type' },
        { value: 'mcq', label: 'MCQ' },
        { value: 'simulation_table', label: 'Simulation Table' },
        { value: 'long_answer', label: 'Long Answer' },
    ];
    return (
        <div>
            <BackNavSection navigateBack={navigateBack} />
            {/* <CreateQuestionFilters /> */}
            {/* <CreateSimulation /> */}
            <CreateMCQ />
        </div>
    )
}

export default CreateQuestion