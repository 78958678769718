import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { tableTestSchema } from '../../../utils/schema';



const CreateTest = () => {
  const { handleSubmit, control, register, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(tableTestSchema),
    defaultValues: {
      rows: [{ a: '', b: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rows',
  });

  const onSubmit = data => {
    console.log(data);
  };

  const handleAddRows = (numRows) => {
    for (let i = 0; i < numRows; i++) {
      append({ a: '', b: '' });
    }
  };

  useEffect(()=>{
  console.log(errors,'errro')
  },[errors])

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Table Test</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <table className="w-full">
          <thead>
            <tr>
              <th></th>
              <th>A</th>
              <th>B</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <Controller
                    name={`rows.${index}.a`}
                    control={control}
                    defaultValue={item.a}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        step="0.01"
                        className={`w-full p-2 border ${errors.rows?.[index]?.a ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    )}
                  />
                  {errors.rows?.[index]?.a && <p className="text-red-500 text-sm">{errors.rows[index].a.message}</p>}
                </td>
                <td>
                  <Controller
                    name={`rows.${index}.b`}
                    control={control}
                    defaultValue={item.b}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        step="0.01"
                        className={`w-full p-2 border ${errors.rows?.[index]?.b ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    )}
                  />
                  {errors.rows?.[index]?.b && <p className="text-red-500 text-sm">{errors.rows[index].b.message}</p>}
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className=" text-white py-2 px-4 rounded w-8 h-8"
                  >
                    <DeleteIcon style={{ color: "tomato" }} />
                  </button>
                  {item?.isQuestion ?    <button
                    type="button" 
                    onClick={() => {
                      setValue(`rows[${index}].isQuestion`, false);
                    }}
                    className="text-white py-2 px-4 rounded w-8 h-8 "
                  >
                    <LockOpenIcon style={{ color: 'black' }} />
                  </button>:   <button
                    type="button"
                    onClick={() => {
                      setValue(`rows[${index}].isQuestion`, true);
                    }}
                    className="text-white py-2 px-4 rounded w-8 h-8 "
                  >
                    <LockIcon style={{ color: 'black' }} />
                  </button>}
               
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex space-x-4">
          <input
            type="number"
            onChange={(e) => {
              const numRows = parseInt(e.target.value);
              setValue('rows', []);
              handleAddRows(numRows);
            }}
            className="w-20 p-2 border border-gray-300"
          />
          <button
            type="button"
            onClick={() => {
              const numRows = parseInt(document.getElementById('numRows').value);
              handleAddRows(numRows);
            }}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            Add Row
          </button>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateTest;
