import React, { useEffect, useState } from 'react';
import { dashboardService } from '../../../service/dashboardService';
import { toast } from 'react-toastify';

const ApprovedListingTable = () => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
  
    const fetchList = async () => {
      setIsLoading(true)
      try {
        const response = await dashboardService().getStudentsList()
        setIsLoading(false)
        if (response.status === 200) {
          setList(response.data.results)
        } 
        console.log(response.data, 'response')
      } catch (error) {
        toast.error('Failed to retireve list')
      }
    }
  
  
    useEffect(() => {
      fetchList()
    }, [])
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Name</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">School</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Grade</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Status</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item,index) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.first_name} {item.last_name}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.school}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.grade}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                {item.is_approved ? 'Approved':"Pending"}
                {/* <button onClick={()=>approveStudentSignup(approval.id)} className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600">Approve</button> */}
                {/* <button className="ml-2 bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600">Reject</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApprovedListingTable;
