// src/components/LandingPage.js
import React from 'react';
import SignupForm from './signupForm/SignupForm';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, ArrowRightAltSharp } from '@mui/icons-material';


const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col md:flex-row bg-secondary min-h-[90vh]">
      <div className="grid place-content-center w-full text-center w-[40%] mx-auto">
        <h2 className='text-[52px] font-playfair font-bold mb-8'>Join the New Gen Learning <p className='text-gold'>Experience</p></h2>
        <p className="text-[20px] mb-8">Easy way to way ace your CBSE accountancy Exam</p>
        <button onClick={() => navigate('/register')} className='p-4 rounded w-1/2 mx-auto bg-highlight hover:bg-hoverColor flex gap-2'>
          <p className="text-white">Get started for free</p>
          <ArrowRightAltSharp style={{ color: '#fff' }} />
        </button>
      </div>
    </div>
  );
}

export default LandingPage;
