import React, { useEffect, useState } from 'react'
import QuestionsTable from './QuestionsTable'
import { questionBankService } from '../../service/questionBankService'
import SimluationPreview from './SimulationPreview'

const SimulationListing = ({ grade, lecture }) => {
  const [list, setList] = useState([])
  const [showPreview, setShowPreview] = useState(false)
  const [selectedId, setSelectedId] = useState(null)


  const onPreviewClick = (id) => {
    setSelectedId(id)
    setShowPreview(true)
  }

  const onDeleteClick = () => {

  }

  const fetchList = async () => {
    try {
      const response = await questionBankService().listSimulations(grade, lecture)
      if (response?.status === 200) {
        setList(response?.data?.results)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <>
      <QuestionsTable list={list} onPreviewClick={onPreviewClick} onDeleteClick={onDeleteClick} />
      {selectedId ? <SimluationPreview isOpen={showPreview} handleClose={() => setShowPreview(false)} id={selectedId} /> : null}
    </>
  )
}

export default SimulationListing