import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { publicService } from '../../../service/publicService'
import TooltipError from '../../../components/tooltipError/TooltipError'

const GradeSelectInput = ({ control, errors }) => {
    const [grades, setGrades] = useState([]);
    const getGrades = async () => {
        try {
            const response = await publicService().getGradesList()
            if(response?.status === 200){
                setGrades(response?.data?.results)
            }
            console.log('grades', response)
        } catch (error) {
            console.log('error', error.message)
        }
    }

    useEffect(() => {
        getGrades()
    }, [])

    return (
        <div>
            <label className="block text-gray-700 text-[12px]">Grade</label>
            <Controller
                name="grade"
                control={control}
                render={({ field }) => (
                    <select {...field} className={`mt-1 p-2 w-full border rounded  text-[12px] ${errors.grade ? 'border-red-500' : 'border-gray-300'}`}>
                        <option value="">Select Grade</option>
                        {grades?.map((grade) => (
                            <option key={grade.id} value={grade.id}>
                                {grade.grade}
                            </option>
                        ))}
                    </select>
                )}
            />
            {errors.grade && <TooltipError message={errors.grade.message} />}
        </div>
    )
}

export default GradeSelectInput