import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../components/logo/Logo'

const LandingLayout = ({ children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className="min-h-screen flex flex-col font-playfair">
            <nav className="bg-primary text-white p-4 px-8 flex justify-between items-center">
               <Logo onClick={()=>navigate('/')} />
               <div className="flex items-center gap-x-12">
               <div className="flex space-x-4">
                    <a href="#about" className="hover:underline text-navyBlue text-[16px] font-playfair">About Us</a>
                    <a href="#pricing" className="hover:underline text-navyBlue text-[16px] font-playfair">Gifts</a>
                    <a href="#contact" className="hover:underline text-navyBlue text-[16px] font-playfair">Contact Us</a>
                </div>
                <Link className="px-4 py-1 bg-highlight rounded text-[14px] hover:bg-hoverColor" to="/login" >Login</Link>
               </div>
            </nav>
            {children}
        </div>
    )
}

export default LandingLayout