// RichTextEditor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const RichTextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill style={{height:'100%'}} value={value} onChange={onChange} />
  );
};

export default RichTextEditor;
