import React, { useEffect, useState } from 'react'
import QuestionsTable from './QuestionsTable'
import { questionBankService } from '../../service/questionBankService'
import MCQPreview from './MCQPreview'


const MCQListing = ({ grade, lecture }) => {
    const [list, setList] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    

    const onPreviewClick = (id) => {
        setSelectedId(id)
        setShowPreview(true)
    }

    const onDeleteClick = () => {

    }

    const fetchList = async () => {
        try {
            const response = await questionBankService().listMCQ(grade, lecture)
            if (response?.status === 200) {
                setList(response?.data?.results)
            }
            console.log('res', response)
        } catch (error) {
            console.log('error', error.message)
        }
    }

    useEffect(() => {
        fetchList()
    }, [])

    return (
        <>
            <QuestionsTable list={list} onPreviewClick={onPreviewClick} onDeleteClick={onDeleteClick} />
            {selectedId ? <MCQPreview isOpen={showPreview} handleClose={()=>setShowPreview(false)}  id={selectedId}/>:null}
             
        </>
    )
}

export default MCQListing