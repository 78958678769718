// ProgressBar.js
import React from 'react';

const ProgressBar = ({ completed, total }) => {
  const percentage = Math.round((completed / total) * 100);

  return (
    <div className="flex items-center w-full">
      <div className="w-full bg-white rounded-full h-4 mr-2">
        <div
          className="bg-amber-500 h-4 rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="text-gray-700">{completed}/{total}</span>
    </div>
  );
};

export default ProgressBar;
