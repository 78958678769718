import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../service/dashboardService'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Profile = () => {
    const [user ,setUser] = useState(null)
    const [isCopied, setIsCopied] = useState(false);

    const fetchProfileData = async()=>{
        try {
            const response = await dashboardService().getProfile()
            setUser(response?.data)
            console.log(response,'response')
        } catch (error) {
            console.log(error.message,'error')
        }
    }

    useEffect(()=>{
        fetchProfileData()
    },[])


    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    };
  

    if(!user) return <div>loading</div>
  return (<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-6">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-2xl">
        <div className="flex items-center mb-4">
          <div className="h-16 w-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl font-bold text-white">
            {user.first_name.charAt(0)}{user.last_name.charAt(0)}
          </div>
          <div className="ml-4">
            <h2 className="text-xl font-bold text-gray-800">{user.first_name} {user.last_name}</h2>
            <p className="text-gray-600">{user.email}</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner">
            <h3 className="text-lg font-semibold text-gray-700">Personal Information</h3>
            <p className="mt-2 text-gray-600"><span className="font-medium">Grade:</span> {user.grade}</p>
            <p className="mt-2 text-gray-600"><span className="font-medium">School:</span> {user.school}</p>
            <p className="mt-2 text-gray-600"><span className="font-medium">WhatsApp Number:</span> {user.whatsapp_num}</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-inner">
            <h3 className="text-lg font-semibold text-gray-700">Referral Information</h3>
            <div className="mt-2 flex items-center text-gray-600">
              <span className="font-medium">Referral Code:</span> {user.referral_code}
              <button
                onClick={() => copyToClipboard(user.referral_code)}
                className="ml-2 p-1 text-blue-500 hover:text-blue-700"
                title="Copy to clipboard"
              >
                <ContentCopyIcon />
              </button>
            </div>
            {isCopied && <p className="text-green-500 mt-2">Copied!</p>}
            <p className="mt-2 text-gray-600"><span className="font-medium">Referred Users:</span> {user.reffered_users}</p>
          </div>
        </div>

        <div className="mt-6">
          {user.is_approved ? (
            <span className="px-4 py-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              Approved
            </span>
          ) : (
            <span className="px-4 py-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Not Approved
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile