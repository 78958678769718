import React, { useEffect, useState } from 'react'
import PendingApprovalsTable from './PendingApprovalsTable'
import ApprovedListingTable from './ApprovedListingTable';

const StudentsListing = () => {

  const [activeTab, setActiveTab] = useState('all');

  return (
    <div className="w-full mx-auto mt-10">
      <div className="flex border-b">
      <button
          className={`w-full py-2 text-center ${
            activeTab === 'all' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('all')}
        >
          All
        </button>
        <button
          className={`w-full py-2 text-center ${
            activeTab === 'pending' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('pending')}
        >
          Pending
        </button>
      </div>
      <div className="p-4">
      {activeTab === 'all' && (
          <div>
            <ApprovedListingTable />
          </div>
        )}
        {activeTab === 'pending' && (
          <div>
            <PendingApprovalsTable/>
          </div>
        )}
      
      </div>
    </div>
  );

}

export default StudentsListing