import { apiAuthenticatedRequest, apiRequest } from "../api/apiRequest";

export const publicService = () => {
  return {
    getGradesList: (token, headers) => {
      return apiRequest({
        method: 'GET',
        url: `/grades/`,
        headers,
      });
    },
    getSchoolAutoComplete: (inputValue, headers) => {
      return apiRequest({
        method: 'GET',
        url: `/search-schools/?q=${inputValue}`,
        headers,
      });
    },
    
    
 
  };
};
