import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../service/dashboardService'
import { useNavigate, useParams } from 'react-router-dom'
import BackNavSection from '../../components/backNav/BackNavSection'

const ModuleDetailView = () => {
    const [data,setData] = useState(null)

    const {id} =  useParams()
    const navigate =useNavigate()
    console.log(id)

    const fetchData = async () => {
        try {
            const response = await dashboardService().getLectureDetails(id)
            console.log(response,'res')
            setData(response?.data)
        } catch (error) {
            console.log(error.message,'error')
        }
    }

    const navigateBack = ()=>{
        navigate('/modules')
    }

    useEffect(()=>{
        fetchData()
    },[])
    return (
        <div>
           <BackNavSection navigateBack={navigateBack} />
            <h2>{data?.title}</h2>
        </div>
    )
}

export default ModuleDetailView