// src/App.js
import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import Login from './pages/auth/login/Login'
import About from './pages/about/About'
import LandingPage from './pages/landing/Landing'
import ProtectedRoute from './routes/ProtectedRoute'
import PublicRoute from './routes/PublicRoute'
import StudentDashboard from './pages/studentDashbaord/StudentDashboard'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CreateTest from './pages/dashboard/testManagement/CreateTest'
import Dashboard from './pages/dashboard/Dashboard'
import StudentsListing from './pages/students/studentsListing/StudentsListing'
import Profile from './pages/profile/Profile'
import Staffs from './pages/staffs/Staffs'
import StudyPlan from './pages/studyPlan/StudyPlan'
import CreateModule from './pages/modulemanagement/CreateModule'
import ModulesListing from './pages/modulemanagement/ModulesListing'
import ModuleDetailView from './pages/modulemanagement/ModuleDetailView'
import QuestionBank from './pages/questionManagement/QuestionBank'
import QuestionbankListing from './pages/questionManagement/QuestionbankListing'
import CreateQuestion from './pages/questionManagement/CreateQuestion'
import TestExerciseLayout from './pages/dashboard/testExercise/TestExerciseLayout'
import Practicetest from './pages/dashboard/practicetest/Practicetest'
import PracticetestLayout from './pages/dashboard/practicetest/PracticetestLayout'
import SimulationTableExerciseView from './pages/dashboard/testExercise/SimulationTableExerciseView'
import Register from './pages/auth/register/Register'

function App () {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path='/' element={<LandingPage />} />
            <Route path='/about' element={<About />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Route>             
          <Route element={<ProtectedRoute redirectPath={'/login'} />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/create-test' element={<CreateTest />} />
            <Route path='/students-listing' element={<StudentsListing />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/staffs' element={<Staffs />} />

            <Route path='/modules' element={<ModulesListing />} />
            <Route path='/create-module' element={<CreateModule />} />
            <Route path='/module-detail/:id' element={<ModuleDetailView />} />

            <Route path='/question-bank' element={<QuestionBank />} />
            <Route path='/question-listing' element={<QuestionbankListing />} />
            <Route path='/create-question' element={<CreateQuestion />} />
            <Route path='/dummy-simulation' element={<TestExerciseLayout/>} />

            
            {/* //student  */}
            <Route path='/study' element={<StudyPlan />} />
            <Route path='/attend-test' element={<TestExerciseLayout/>} />
            <Route path='/practice-test' element={<Practicetest/>} />
            <Route path='/attend-practice' element={<PracticetestLayout/>} />

            

          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </Provider>
  )
}

export default App
