import React from 'react'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import McqExerciseView from '../testExercise/McqExerciseView'
import Timer from '../../../components/timer/Timer'

function PracticetestLayout() {

    return (
        <div className=''>
            <div className="flex w-full justify-between items-center  border-b-2 p-2">
                <p className="text-xl font-bold">Practice test - MCQ</p>
                <Timer />
                <PrimaryButton type="button" className='text-white bg-[tomato]' >
                    Exit
                </PrimaryButton>
            </div>
            <div className='flex items-center justify-start p-4'>
                <p className={`text-xl  text-amber-500 font-bold `}>1/24 </p>
            
            </div>

            <div className="flex bg-gray-200">
                {/* //exam area */}
                <div className="w-2/3 bg-white border rounded p-2 h-[80vh] overflow-auto" >
                    <McqExerciseView/>
                    <div className='flex items-center justify-center gap-2'>
                        <div className='cursor-pointer border p-4 rounded bg-gray-100'>
                            <ChevronLeft />
                        </div>
                        <div className='cursor-pointer  border p-4 rounded'>
                            <ChevronRight />
                        </div>
                    </div>
                </div>
                <div className="h-full">

                </div>
            </div>
        </div>
    )
}

export default PracticetestLayout