import React from 'react'
import Header from '../components/header/Header'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DashboardLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  console.log(user, 'user')
  const location = useLocation()
  console.log(location.pathname,'path')
  const renderNavItems = () => {
    switch (user.role) {
      case 'admin':
        return (
          <nav className='flex flex-col gap-4'>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Overall
            </NavLink>
            <NavLink
              to="/staffs"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Staffs
            </NavLink>
            <NavLink
              to="/students-listing"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Students
            </NavLink>
            <NavLink
              to="/modules"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Modules
            </NavLink>
            <NavLink
              to="/question-bank"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Question Bank
            </NavLink>
          </nav>
        );
      case 'staff':
        return (
          <nav className='flex flex-col gap-4'>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Overall
            </NavLink>
            <NavLink
              to="/students-listing"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Students
            </NavLink>
            <NavLink
              to="/question-bank"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Question Bank
            </NavLink>
          </nav>
        );
      case 'student':
        return (
          <nav className='flex flex-col gap-4'>
            <NavLink
              to="/practice-test"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Practice Test
            </NavLink>
            <NavLink
              to="/study"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Study
            </NavLink>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Profile
            </NavLink>
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-500' : 'text-lg hover:bg-gray-500'
              }
            >
              Settings
            </NavLink>
          </nav>
        );
      default:
        return null;
    }
  };

  return (
    <div className='h-screen'>
      <Header user={user} />
      <div className="flex h-[93%]">
        {location.pathname === '/attend-test' || location.pathname === '/attend-practice' || location.pathname === '/dummy-simulation'   ? null:  <aside className="w-64 bg-gray-800 text-white p-4">
          {renderNavItems()}
        </aside>}
      
        <main className="flex-1 bg-white p-4 overflow-y-auto w-full">
          {children}
        </main>
      </div>
    </div>
  )
}

export default DashboardLayout