import React from 'react'

const Logo = ({ onClick }) => {
    return (
        <div onClick={onClick} className='cursor-pointer w-[120px]  text-center '>
            <div className="flex items-center justify-center gap-2">
                <p className="text-xl font-bold text-[#000] ">T</p>
                <p className="text-xl font-bold text-[#000080]">C</p>
                <p className="text-xl font-bold text-gold">A</p>
            </div>
            <p className="text-[8px] font-bold text-highlight">The Class of Accounting</p>
        </div>
    )
}

export default Logo