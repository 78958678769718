import React from 'react'
import QuestionsTable from './QuestionsTable'

const LongAnswerListing = () => {

    const onPreviewClick = ()=>{

    }

    const onDeleteClick = ()=>{
        
    }
  return (
    <QuestionsTable  list={[]} onPreviewClick={onPreviewClick} onDeleteClick={onDeleteClick} />
  )
}

export default LongAnswerListing