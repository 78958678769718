import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { questionBankService } from '../../service/questionBankService';


const SimluationPreview = ({ isOpen, handleClose, id }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getSimulationDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  useEffect(() => {
    if(id) fetchData()
  }, [id])

  
  return (
    <Modal open={isOpen} onClose={handleClose}>
     {loading ? <div>Loading....</div>: <div className="p-6 rounded-lg shadow-md max-w-xl mx-auto mt-[20%] bg-white">
        <h2 className="text-xl font-semibold mb-4">{data?.question}</h2>
        <div className="grid grid-cols-2 gap-4">
          {/* {optionLabels.map(option => (
            <div key={option} className={`p-4 rounded-lg shadow-md ${data?.correct_option === option  ? 'bg-green-100' : 'bg-white'} `}>
              <label className="flex items-center space-x-3">
                <span className="text-gray-700">{data[`option_${option.toLowerCase()}`]}</span>
              </label>
            </div>
          ))} */}
        </div>
      </div>}
    
    </Modal>
  );
};

export default SimluationPreview;
