import React, { useState } from 'react'
import { CheckBoxOutlineBlank, ChevronLeft, ChevronRight, Delete } from '@mui/icons-material'
import PrimaryButton from '../../../components/button/PrimaryButton'
import CheckBox from '@mui/icons-material/CheckBox'
import { useNavigate } from 'react-router-dom'

function Practicetest() {
    const [selected,setSelected] = useState([])
    const arr =['Module -1','Module -2','Module -3']
    const navigate = useNavigate()
const handleSelection =(item)=>{
    if(selected.includes(item)){
        setSelected(prev => prev.filter(i => i != item))
    }else{
        setSelected(prev => [...prev,item])
    }
}
    return (
        <div className=''>
            <div className="container mx-auto p-4 ">
                <div className="w-1/4 mb-8">
                    <label htmlFor="question_type" className="block text-sm font-medium text-gray-700">Question Type</label>
                    <select id="question_type" name="question_type" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option value="multiple_choice">Multiple Choice</option>
                        <option value="true_false">Long Answer</option>
                        <option value="short_answer">Simulation Table</option>
                    </select>
                </div>
                <div className="border w-1/3 p-4 rounded">
                  {arr.map(i =>   <div onClick={()=>handleSelection(i)} className="flex cursor-pointer items-center gap-2">
                       {selected.includes(i) ? <CheckBox />:<CheckBoxOutlineBlank />} 
                        <p className="text-sm">{i} </p>
                    </div>)}
                </div>
            </div>

            <PrimaryButton onClick={()=>navigate("/attend-practice")}>Start</PrimaryButton>
        </div>
    )
}

export default Practicetest